var backend_server_url = 'https://www.startdeck.com/backend';
var frontend_url  = 'https://www.startdeck.com';

export const environment = {
  production: true,
  vapiKey: 'BM-Q3rUecswquVXm8hmSUX078sg2tACqvvrnY5L1fEgrA_OdRjFRYiEAop8tW8hNphaKGrCE1WrQOfZ-2eq6rcE',
  pusher: {
    key: '8f75fffedd59c91a6e07',
    cluster: 'us2',
  },
  GOOGLE_ANALYTICS_ID: 'UA-129440044-1',
  GOOGLE_TAG_ID:'AW-707983293',
  bugsnag_api_key: '9f5245ed8c19d78bf181bc4a05eda8ff',
  backend_server_url: backend_server_url,
  apiEndpoint: backend_server_url + '/api/',
  frontend_url :frontend_url,
  sharethisPropertyId:'657325aba6ecd400190b3b55',
  GOOGLE_MAP_ID:'2096ff793a2dd414'
};